import { ComponentPropsWithoutRef, useId } from 'react';

const MembershipFree = (props: ComponentPropsWithoutRef<'svg'>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 6 }, (_, i) => `${id}-${i}`);

  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0843 20.3336C15.3323 20.3336 19.5866 16.3523 19.5866 11.4412C19.5866 6.53008 15.3323 2.54883 10.0843 2.54883C4.83635 2.54883 0.582031 6.53008 0.582031 11.4412C0.582031 16.3523 4.83635 20.3336 10.0843 20.3336Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M10.084 19.0493C15.6068 19.0493 20.084 14.8595 20.084 9.69114C20.084 4.52279 15.6068 0.333008 10.084 0.333008C4.56114 0.333008 0.0839844 4.52279 0.0839844 9.69114C0.0839844 14.8595 4.56114 19.0493 10.084 19.0493Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        d="M10.0843 18.5826C15.3323 18.5826 19.5866 14.6014 19.5866 9.69023C19.5866 4.77911 15.3323 0.797852 10.0843 0.797852C4.83635 0.797852 0.582031 4.77911 0.582031 9.69023C0.582031 14.6014 4.83635 18.5826 10.0843 18.5826Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <path
        d="M10.0836 18.2336C15.1251 18.2336 19.2121 14.409 19.2121 9.69103C19.2121 4.97309 15.1251 1.14844 10.0836 1.14844C5.04206 1.14844 0.955078 4.97309 0.955078 9.69103C0.955078 14.409 5.04206 18.2336 10.0836 18.2336Z"
        fill={`url(#${gradientIds[3]})`}
      />
      <path
        d="M10.0827 17.7957C14.8659 17.7957 18.7435 14.167 18.7435 9.69084C18.7435 5.21463 14.8659 1.58594 10.0827 1.58594C5.29945 1.58594 1.42188 5.21463 1.42188 9.69084C1.42188 14.167 5.29945 17.7957 10.0827 17.7957Z"
        fill={`url(#${gradientIds[4]})`}
      />
      <g style={{ mixBlendMode: 'color-dodge' }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0812 3.69922C9.72353 3.70193 9.36654 3.8262 9.0936 4.07204C8.54172 4.56931 8.54172 5.37493 9.0936 5.87211L12.8736 9.27725L13.7624 8.47664L13.786 8.45507C14.3752 7.90161 14.3673 7.02242 13.7624 6.47749L11.0919 4.07204L11.068 4.05113C10.7983 3.81906 10.4519 3.70176 10.1044 3.69922H10.0812ZM5.58984 7.46007L12.7827 13.954L14.7667 12.1628L7.57376 5.66895L5.58984 7.46007ZM4.09405 8.93469L4.07079 8.95602C3.81248 9.19768 3.68273 9.50857 3.68164 9.8198V9.82784C3.68282 10.1482 3.82029 10.4682 4.09405 10.7127C4.64412 11.2038 5.5359 11.2038 6.0857 10.7127C6.63576 10.2216 6.63576 9.42581 6.0857 8.93469L5.09006 8.0459L4.09405 8.93469ZM14.2697 8.82412C13.72 9.31524 13.72 10.111 14.2697 10.602L15.2657 11.4912L16.2616 10.602L16.2849 10.5809C16.8113 10.0884 16.8037 9.30826 16.2616 8.82412C15.9865 8.57877 15.626 8.45605 15.2656 8.45605C14.9052 8.45605 14.5447 8.57877 14.2697 8.82412ZM6.59193 11.1465L6.56785 11.1687C5.9799 11.7179 5.98789 12.3988 6.59193 12.9445L9.26565 15.3853L9.28946 15.4062C9.56086 15.6403 9.90994 15.7577 10.2595 15.7587H10.268C10.6279 15.7577 10.9874 15.6333 11.2617 15.3853C11.8131 14.8875 11.8131 14.0803 11.2617 13.5824L7.47981 10.3447L6.59193 11.1465Z"
          fill={`url(#${gradientIds[5]})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="0.581811"
          y1="11.4418"
          x2="19.5868"
          y2="11.4418"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2769" stopColor="#1E3875" />
          <stop offset="0.5333" stopColor="#085BDF" />
          <stop offset="0.7282" stopColor="#1E3875" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="8.51535"
          y1="0.181572"
          x2="11.2072"
          y2="18.8157"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0615" stopColor="#A8ACAF" />
          <stop offset="0.1846" stopColor="#F7F7F7" />
          <stop offset="0.2769" stopColor="#A8ACAF" />
          <stop offset="0.5333" stopColor="#C8CCCE" />
          <stop offset="0.7282" stopColor="#A8ACAF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="0.581811"
          y1="9.69038"
          x2="19.5868"
          y2="9.69038"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2769" stopColor="#1E3875" />
          <stop offset="0.5333" stopColor="#085BDF" />
          <stop offset="0.7282" stopColor="#1E3875" />
        </linearGradient>
        <linearGradient
          id={gradientIds[3]}
          x1="8.65188"
          y1="1.0115"
          x2="11.1087"
          y2="18.0192"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0615" stopColor="#A8ACAF" />
          <stop offset="0.1846" stopColor="#F7F7F7" />
          <stop offset="0.2769" stopColor="#A8ACAF" />
          <stop offset="0.5333" stopColor="#C8CCCE" />
          <stop offset="0.7282" stopColor="#A8ACAF" />
        </linearGradient>
        <radialGradient
          id={gradientIds[4]}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.0827 9.69098) scale(9.65339 9.03377)"
        >
          <stop offset="0.00852266" stopColor="#D1D0CE" />
          <stop offset="1" stopColor="#6C728A" />
        </radialGradient>
        <linearGradient
          id={gradientIds[5]}
          x1="12.9467"
          y1="5.93606"
          x2="6.869"
          y2="13.3489"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F170A8" />
          <stop offset="0.473958" stopColor="#D561CE" />
          <stop offset="1" stopColor="#6EBFFA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MembershipFree;
