import { ComponentPropsWithoutRef, useId } from 'react';

const MembershipPlus = (props: ComponentPropsWithoutRef<'svg'>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 4 }, (_, i) => `${id}-${i}`);

  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9174 20.3337C16.332 20.3337 20.7214 16.1493 20.7214 10.9876C20.7214 5.82596 16.332 1.6416 10.9174 1.6416C5.50272 1.6416 1.11328 5.82596 1.11328 10.9876C1.11328 16.1493 5.50272 20.3337 10.9174 20.3337Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M10.9174 19.2126C16.332 19.2126 20.7214 15.0282 20.7214 9.86653C20.7214 4.70487 16.332 0.520508 10.9174 0.520508C5.50272 0.520508 1.11328 4.70487 1.11328 9.86653C1.11328 15.0282 5.50272 19.2126 10.9174 19.2126Z"
        fill="#2E5293"
      />
      <path
        d="M10.919 18.5219C15.9333 18.5219 19.9981 14.6469 19.9981 9.86688C19.9981 5.08688 15.9333 1.21191 10.919 1.21191C5.90472 1.21191 1.83984 5.08688 1.83984 9.86688C1.83984 14.6469 5.90472 18.5219 10.919 18.5219Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        d="M10.916 19.3977C5.40269 19.3977 0.916016 15.1225 0.916016 9.86678C0.916016 4.61104 5.40073 0.333984 10.916 0.333984C16.4293 0.333984 20.916 4.60918 20.916 9.86678C20.916 15.1225 16.4313 19.3977 10.916 19.3977ZM10.916 0.707526C5.61821 0.707526 1.30787 4.81649 1.30787 9.86678C1.30787 14.9171 5.61821 19.0242 10.916 19.0242C16.2138 19.0242 20.5242 14.9152 20.5242 9.86678C20.5242 4.81649 16.2138 0.707526 10.916 0.707526Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <g style={{ mixBlendMode: 'color-dodge' }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.911 3.51074C10.5604 3.51345 10.2105 3.63753 9.94291 3.88301C9.40195 4.37953 9.40195 5.18395 9.94291 5.68039L13.6481 9.08045L14.5193 8.28103L14.5424 8.25949C15.12 7.70686 15.1122 6.82898 14.5193 6.28486L11.9016 3.88301L11.8782 3.86213C11.6139 3.63041 11.2743 3.51328 10.9337 3.51074H10.911ZM6.50977 7.26501L13.5603 13.7492L15.505 11.9608L8.45443 5.47656L6.50977 7.26501ZM5.04292 8.73903L5.02012 8.76033C4.76692 9.00162 4.63974 9.31205 4.63867 9.62281V9.63084C4.63983 9.95077 4.77458 10.2703 5.04292 10.5144C5.5821 11.0048 6.45624 11.0048 6.99515 10.5144C7.53433 10.024 7.53433 9.22941 6.99515 8.73903L6.01922 7.85156L5.04292 8.73903ZM15.0174 8.62826C14.4786 9.11864 14.4786 9.91322 15.0174 10.4035L15.9937 11.2913L16.9699 10.4035L16.9927 10.3824C17.5087 9.89062 17.5012 9.11168 16.9699 8.62826C16.7002 8.38328 16.3469 8.26074 15.9936 8.26074C15.6403 8.26074 15.287 8.38328 15.0174 8.62826ZM7.493 10.947L7.4694 10.9692C6.89308 11.5176 6.90092 12.1975 7.493 12.7424L10.1138 15.1795L10.1372 15.2004C10.4032 15.4341 10.7454 15.5514 11.088 15.5524H11.0963C11.4491 15.5514 11.8014 15.4271 12.0704 15.1795C12.6108 14.6825 12.6108 13.8765 12.0704 13.3794L8.36331 10.1465L7.493 10.947Z"
          fill={`url(#${gradientIds[3]})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="1.96998"
          y1="10.9876"
          x2="20.4849"
          y2="10.9876"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0974" stopColor="#F7F7F7" />
          <stop offset="0.4205" stopColor="#A8ACAF" />
          <stop offset="0.7282" stopColor="#6C728A" />
          <stop offset="1" stopColor="#A8ACAF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="8.05759"
          y1="6.06"
          x2="16.1458"
          y2="17.9052"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDEBA0" stopOpacity="0" />
          <stop offset="1" stopColor="#2C42BD" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="9.34872"
          y1="0.18199"
          x2="12.1391"
          y2="19.1446"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0615" stopColor="#A8ACAF" />
          <stop offset="0.1846" stopColor="#F7F7F7" />
          <stop offset="0.2769" stopColor="#A8ACAF" />
          <stop offset="0.5333" stopColor="#C8CCCE" />
          <stop offset="0.8923" stopColor="#A8ACAF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[3]}
          x1="13.7205"
          y1="5.74427"
          x2="7.63079"
          y2="13.0357"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F170A8" />
          <stop offset="0.473958" stopColor="#D561CE" />
          <stop offset="1" stopColor="#6EBFFA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MembershipPlus;
