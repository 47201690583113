import { ComponentPropsWithoutRef, useId } from 'react';

const MembershipPremium = (props: ComponentPropsWithoutRef<'svg'>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 4 }, (_, i) => `${id}-${i}`);

  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0873 20.3342C15.5019 20.3342 19.8914 16.1495 19.8914 10.9874C19.8914 5.82533 15.5019 1.64062 10.0873 1.64062C4.67264 1.64062 0.283203 5.82533 0.283203 10.9874C0.283203 16.1495 4.67264 20.3342 10.0873 20.3342Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M10.0853 19.2141C15.5 19.2141 19.8894 15.0294 19.8894 9.86731C19.8894 4.70521 15.5 0.520508 10.0853 0.520508C4.67069 0.520508 0.28125 4.70521 0.28125 9.86731C0.28125 15.0294 4.67069 19.2141 10.0853 19.2141Z"
        fill="#912D96"
      />
      <path
        d="M10.087 18.5233C15.1012 18.5233 19.1661 14.648 19.1661 9.8676C19.1661 5.0872 15.1012 1.21191 10.087 1.21191C5.07269 1.21191 1.00781 5.0872 1.00781 9.8676C1.00781 14.648 5.07269 18.5233 10.087 18.5233Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        d="M10.084 19.3993C4.57066 19.3993 0.0839844 15.1237 0.0839844 9.86757C0.0839844 4.6114 4.5687 0.333984 10.084 0.333984C15.5973 0.333984 20.084 4.60953 20.084 9.86757C20.084 15.1237 15.5993 19.3993 10.084 19.3993ZM10.084 0.709426C4.78617 0.709426 0.475834 4.81873 0.475834 9.86944C0.475834 14.9201 4.78617 19.0276 10.084 19.0276C15.3818 19.0276 19.6921 14.9183 19.6921 9.86944C19.6921 4.81686 15.3818 0.709426 10.084 0.709426Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0789 3.88184C9.72826 3.88454 9.37834 4.00864 9.1108 4.25414C8.56984 4.7507 8.56984 5.55518 9.1108 6.05166L12.816 9.452L13.6872 8.65252L13.7103 8.63098C14.2879 8.0783 14.2801 7.20035 13.6872 6.65619L11.0695 4.25414L11.0461 4.23325C10.7818 4.00151 10.4422 3.88438 10.1016 3.88184H10.0789ZM5.67773 7.63625L12.7283 14.121L14.673 12.3324L7.6224 5.84766L5.67773 7.63625ZM4.21089 9.11034L4.18809 9.13163C3.93489 9.37295 3.80771 9.68341 3.80664 9.99419V10.0022C3.8078 10.3222 3.94255 10.6417 4.21089 10.8858C4.75007 11.3762 5.62421 11.3762 6.16312 10.8858C6.7023 10.3954 6.7023 9.60076 6.16312 9.11034L5.18719 8.2228L4.21089 9.11034ZM14.1854 8.99939C13.6465 9.48981 13.6465 10.2845 14.1854 10.7748L15.1617 11.6627L16.1379 10.7748L16.1607 10.7537C16.6767 10.2618 16.6692 9.48284 16.1379 8.99939C15.8682 8.75438 15.5149 8.63184 15.1616 8.63184C14.8083 8.63184 14.455 8.75438 14.1854 8.99939ZM6.66097 11.3182L6.63737 11.3404C6.06105 11.8888 6.06889 12.5688 6.66097 13.1137L9.28178 15.551L9.30512 15.5719C9.57115 15.8056 9.91332 15.9229 10.2559 15.9239H10.2643C10.6171 15.9229 10.9694 15.7987 11.2384 15.551C11.7788 15.054 11.7788 14.2479 11.2384 13.7507L7.53128 10.5176L6.66097 11.3182Z"
        fill={`url(#${gradientIds[3]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="1.1399"
          y1="10.9874"
          x2="19.6548"
          y2="10.9874"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0974" stopColor="#FCFBCC" />
          <stop offset="0.4205" stopColor="#FFD86C" />
          <stop offset="0.7282" stopColor="#F58327" />
          <stop offset="1" stopColor="#FDEBA0" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="7.22556"
          y1="6.06041"
          x2="15.3147"
          y2="17.906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDEBA0" stopOpacity="0" />
          <stop offset="1" stopColor="#B842BD" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="10.0331"
          y1="0.335049"
          x2="10.1273"
          y2="19.4001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDEBA0" />
          <stop offset="0.4564" stopColor="#FFD86C" />
          <stop offset="0.641" stopColor="#FCFBCC" />
          <stop offset="1" stopColor="#FEE183" />
        </linearGradient>
        <linearGradient
          id={gradientIds[3]}
          x1="10.1493"
          y1="3.79169"
          x2="10.2226"
          y2="18.1476"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0974" stopColor="#FCFBCC" />
          <stop offset="0.4205" stopColor="#FFD86C" />
          <stop offset="0.7231" stopColor="#F9A341" />
          <stop offset="1" stopColor="#FDEBA0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MembershipPremium;
