// NOTE: do not move it in shared directory or it will create circular deps because of the icons

import { MembershipTierId } from '@api';

import {
  MembershipFree,
  MembershipPlus,
  MembershipPremium,
  MembershipPremiumPlus,
} from '@shared/constants/icons';

type MembershipTiers = Record<
  MembershipTierId,
  {
    color: string;
    gradient: string;
    name: string;
    icon: typeof MembershipFree;
  }
>;

export const membershipTiers: MembershipTiers = {
  FREE: {
    color: '#B1AFBA',
    gradient: 'linear-gradient(90deg, #D1D0D5 0%, #67647F 100%)',
    name: 'Free',
    icon: MembershipFree,
  },
  PLUS: {
    color: '#005BC3',
    gradient: 'linear-gradient(90deg, #005BC3 0%, #469FFE 100%)',
    name: 'Plus',
    icon: MembershipPlus,
  },
  PREMIUM: {
    color: '#926CCF',
    gradient: 'linear-gradient(90deg, #926CCF 0%, #523FC1 100%)',
    name: 'Premium',
    icon: MembershipPremium,
  },
  PREMIUM_PLUS: {
    color: '#E27625',
    gradient: 'linear-gradient(270deg, #E27625 0%, #FB8C00 100%)',
    name: 'Premium Plus',
    icon: MembershipPremiumPlus,
  },
};
