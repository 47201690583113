import { ComponentPropsWithoutRef, useId } from 'react';

const MembershipPremiumPlus = (props: ComponentPropsWithoutRef<'svg'>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 5 }, (_, i) => `${id}-${i}`);

  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9378 20.0008C16.1858 20.0008 20.4401 16.0195 20.4401 11.1083C20.4401 6.19711 16.1858 2.21582 10.9378 2.21582C5.68987 2.21582 1.43555 6.19711 1.43555 11.1083C1.43555 16.0195 5.68987 20.0008 10.9378 20.0008Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M10.9375 19.0915C16.4603 19.0915 20.9375 14.9016 20.9375 9.73323C20.9375 4.56482 16.4603 0.375 10.9375 0.375C5.41465 0.375 0.9375 4.56482 0.9375 9.73323C0.9375 14.9016 5.41465 19.0915 10.9375 19.0915Z"
        fill="#FCFBCC"
      />
      <path
        d="M10.9375 18.7174C16.4603 18.7174 20.9375 14.5276 20.9375 9.3592C20.9375 4.1908 16.4603 0.000976562 10.9375 0.000976562C5.41465 0.000976562 0.9375 4.1908 0.9375 9.3592C0.9375 14.5276 5.41465 18.7174 10.9375 18.7174Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        d="M10.9365 17.3185C15.6337 17.3185 19.4414 13.7551 19.4414 9.35947C19.4414 4.96379 15.6337 1.40039 10.9365 1.40039C6.23941 1.40039 2.43164 4.96379 2.43164 9.35947C2.43164 13.7551 6.23941 17.3185 10.9365 17.3185Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <path
        d="M10.9385 2.56573C15.4258 2.56573 19.0996 5.81857 19.4194 9.94307C19.4334 9.75041 19.4454 9.55587 19.4454 9.35947C19.4454 4.96374 15.6377 1.40039 10.9405 1.40039C6.24335 1.40039 2.43164 4.96374 2.43164 9.35947C2.43164 9.55587 2.44163 9.75041 2.45763 9.94307C2.77743 5.81857 6.44923 2.56573 10.9385 2.56573Z"
        fill={`url(#${gradientIds[3]})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7355 3.74219C10.3778 3.74489 10.0208 3.86917 9.7479 4.11502C9.19601 4.61229 9.19601 5.41791 9.7479 5.9151L13.5279 9.32028L14.4167 8.51966L14.4403 8.49809C15.0295 7.94462 15.0216 7.06542 14.4167 6.52049L11.7462 4.11502L11.7223 4.0941C11.4526 3.86203 11.1062 3.74473 10.7587 3.74219H10.7355ZM6.24414 7.50208L13.437 13.996L15.421 12.2049L8.22806 5.71094L6.24414 7.50208ZM4.74835 8.97781L4.72509 8.99914C4.46678 9.2408 4.33703 9.5517 4.33594 9.86292V9.87096C4.33712 10.1914 4.47459 10.5114 4.74835 10.7558C5.29841 11.2469 6.1902 11.2469 6.73999 10.7558C7.29006 10.2647 7.29006 9.46893 6.73999 8.97781L5.74435 8.08901L4.74835 8.97781ZM14.924 8.86807C14.3743 9.35919 14.3743 10.155 14.924 10.646L15.92 11.5351L16.9159 10.646L16.9392 10.6248C17.4656 10.1323 17.458 9.35222 16.9159 8.86807C16.6408 8.62272 16.2803 8.5 15.9199 8.5C15.5595 8.5 15.199 8.62272 14.924 8.86807ZM7.24818 11.1895L7.2241 11.2117C6.63615 11.7609 6.64414 12.4418 7.24818 12.9875L9.9219 15.4283L9.94571 15.4492C10.2171 15.6833 10.5662 15.8007 10.9157 15.8017H10.9243C11.2842 15.8007 11.6436 15.6763 11.918 15.4283C12.4693 14.9306 12.4693 14.1233 11.918 13.6254L8.13606 10.3877L7.24818 11.1895Z"
        fill={`url(#${gradientIds[4]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="1.43435"
          y1="11.1089"
          x2="20.4394"
          y2="11.1089"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2769" stopColor="#791E0B" />
          <stop offset="0.5333" stopColor="#B70D02" />
          <stop offset="0.7282" stopColor="#791E0B" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="10.8839"
          y1="-0.140154"
          x2="10.9699"
          y2="17.6024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDEBA0" />
          <stop offset="0.4564" stopColor="#FFD86C" />
          <stop offset="0.641" stopColor="#FCFBCC" />
          <stop offset="1" stopColor="#FEE183" />
        </linearGradient>
        <radialGradient
          id={gradientIds[2]}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.9355 9.35962) scale(8.50554 7.95967)"
        >
          <stop offset="0.5333" stopColor="#F89D3C" />
          <stop offset="1" stopColor="#E76503" />
        </radialGradient>
        <linearGradient
          id={gradientIds[3]}
          x1="2.43202"
          y1="5.67136"
          x2="19.4431"
          y2="5.67136"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2769" stopColor="#791E0B" />
          <stop offset="0.5333" stopColor="#B70D02" />
          <stop offset="0.7282" stopColor="#791E0B" />
        </linearGradient>
        <linearGradient
          id={gradientIds[4]}
          x1="10.8065"
          y1="3.65192"
          x2="10.8786"
          y2="18.0287"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0974" stopColor="#FCFBCC" />
          <stop offset="0.4205" stopColor="#FFD86C" />
          <stop offset="0.7231" stopColor="#F9A341" />
          <stop offset="1" stopColor="#FDEBA0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MembershipPremiumPlus;
