import { ComponentPropsWithoutRef, useId } from 'react';

// separate component to prevent id collisions
const MsqLogo = (props: ComponentPropsWithoutRef<'svg'>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 5 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 70 70.5" fill="none" {...props}>
      <path
        fill={`url(#${gradientIds[0]})`}
        d="M38.617 2.623c-2.933-2.83-7.691-2.83-10.626 0-2.935 2.83-2.935 7.416 0 10.246l20.102 19.384 4.726-4.558c3.259-3.141 3.259-8.237 0-11.38L38.617 2.623Z"
      />
      <path
        fill={`url(#${gradientIds[1]})`}
        d="m20.412 11.325 39.272 38.463-10.832 10.609L9.579 21.934l10.832-10.61Z"
      />
      <path
        fill={`url(#${gradientIds[2]})`}
        d="m20.426 38.747 20.112 18.99a7.455 7.455 0 0 1 0 10.573c-2.93 2.92-7.683 2.92-10.615 0L15.705 53.995c-3.256-3.243-3.256-7.302 0-10.545l4.721-4.703Z"
      />
      <path
        fill={`url(#${gradientIds[3]})`}
        d="M67.843 29.39a7.299 7.299 0 0 0-10.422 0c-2.877 2.918-2.877 7.648 0 10.567l5.21 5.285 5.212-5.285c2.876-2.919 2.876-7.649 0-10.568Z"
      />
      <path
        fill={`url(#${gradientIds[4]})`}
        d="m7.369 25.758 5.21 5.283c2.877 2.92 2.877 7.65 0 10.569a7.295 7.295 0 0 1-10.421 0c-2.877-2.92-2.877-7.65 0-10.569l5.21-5.283Z"
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="49.918"
          x2="14.217"
          y1="13.484"
          y2="53.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F170A8" />
          <stop offset=".474" stopColor="#D561CE" />
          <stop offset="1" stopColor="#6EBFFA" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="49.918"
          x2="14.217"
          y1="13.484"
          y2="53.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F170A8" />
          <stop offset=".474" stopColor="#D561CE" />
          <stop offset="1" stopColor="#6EBFFA" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="49.918"
          x2="14.217"
          y1="13.484"
          y2="53.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F170A8" />
          <stop offset=".474" stopColor="#D561CE" />
          <stop offset="1" stopColor="#6EBFFA" />
        </linearGradient>
        <linearGradient
          id={gradientIds[3]}
          x1="49.918"
          x2="14.217"
          y1="13.484"
          y2="53.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F170A8" />
          <stop offset=".474" stopColor="#D561CE" />
          <stop offset="1" stopColor="#6EBFFA" />
        </linearGradient>
        <linearGradient
          id={gradientIds[4]}
          x1="49.918"
          x2="14.217"
          y1="13.484"
          y2="53.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F170A8" />
          <stop offset=".474" stopColor="#D561CE" />
          <stop offset="1" stopColor="#6EBFFA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MsqLogo;
